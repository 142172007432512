import Layout from "../components/structural/layout"
import React from "react"
import Sections from "../components/sections/sections"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { useStoryblokState } from "gatsby-source-storyblok"

const IndexPTBRPage = props => {
  const { data } = props
  let story = data.index
  story = useStoryblokState(story)

  return (
    <Layout>
      {story?.content?.content && <Sections items={story?.content?.content} />}
    </Layout>
  )
}

export default IndexPTBRPage

export const Head = ({ data }) => {
  const story = useStoryblokState(data.index)

  const { seo, seo_author, seo_image } = story.content

  const siteSettings =
    data?.settings?.content && JSON.parse(data?.settings?.content)

  return (
    <Seo
      settings={siteSettings}
      {...seo}
      author={seo_author}
      image={seo_image}
    />
  )
}

export const QUERY = graphql`
  query IndexPTBRQuery {
    index: storyblokEntry(slug: { eq: "home" }, lang: { eq: "pt-br" }) {
      content
      name
      full_slug
      uuid
      lang
      id
      internalId
    }

    settings: storyblokEntry(
      full_slug: { eq: "site-settings" }
      lang: { eq: "pt-br" }
    ) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`
